<template>
	<div class="main-content">
		<el-form inline size="small" label-width="100px">
            <button-permissions :datas="'addNotice'">
                <el-form-item>
                    <el-button type="primary" @click="addItem">新增公告</el-button>
                </el-form-item>
            </button-permissions>
			<el-form-item label="公告标题：">
				<el-input class="input-width" v-model="filter.title" placeholder="请输入公告名称搜索"></el-input>
			</el-form-item>
            <el-form-item label="状态：">
				<el-select v-model="filter.status" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="待发布" :value="0"></el-option>
                    <el-option label="已发布" :value="1"></el-option>
                </el-select>

			</el-form-item>
			<el-form-item label="创建日期：">
                <el-date-picker
                    v-model="filter.createTime"
                    format="yyyy-MM-dd" 
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期时间">
                </el-date-picker>
			</el-form-item>
            <el-form-item label="发布日期：">
                <el-date-picker
                    v-model="filter.publishTime"
                    format="yyyy-MM-dd" 
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期时间">
                </el-date-picker>
			</el-form-item>


			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="list" v-loading="tableLoading" border>
			<el-table-column prop="title" label="公告标题"></el-table-column>
			<el-table-column label="公告状态">
				<template slot-scope="scope">
					{{scope.row.status == 0 ? '待发布':'已发布'}}
				</template>
			</el-table-column>
			<el-table-column prop="taskPublishTime" label="定时发布时间" ></el-table-column>
			<el-table-column prop="createTime" label="创建时间" ></el-table-column>
			<el-table-column prop="publishTime" label="发布时间"></el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
                    <button-permissions :datas="'releaseNotice'" v-if="scope.row.status == 0">
                        <el-button type="text" class="pointers" style="margin-right:10px;" @click="updateNoticeStatus(scope)">发布</el-button>
                    </button-permissions>
                    <button-permissions :datas="'editNotice'">
					    <el-button type="text" class="pointers" style="margin-right:10px;" @click="editItem(scope)">编辑</el-button>
                    </button-permissions>
                    <button-permissions :datas="'delNotice'">
					    <el-button type="text" class="pointers" @click="deleteItem(scope)">删除</el-button>
                    </button-permissions>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination 
            style="text-align: right; padding: 15px" 
            :current-page="filter.pageNo" 
            :page-size="filter.pageSize"
			:total="total" 
            :page-sizes="filter.sizes" 
            @size-change="pageSizeChange"
			@current-change="currentChange" 
            background layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

		<!--  -->
		<el-dialog :title="addEditTitle" :visible.sync="addEditFlag" width="800px" @closed="clearDataFunc">
			<AddEdit v-if="addEditData" :formData="addEditData" @closeLayer="closedLayer" ></AddEdit>
		</el-dialog>
	</div>
</template>

<script>
	import { 
        findNoticeList, 
        delNotice,
        updateNoticeStatus,
    } from '@/api/setting';
	import AddEdit from './addEdit';
	export default {
		components: {
			AddEdit,
		},
		data() {
			return {
				tableLoading: false,
				filter: {
					title: '',  //标题
					status: '', //状态：0：未发布，1：已发布
                    createTime:'',  
                    publishTime:'',
					pageNo: 1,
					pageSize: 10,
				},
                total: 0,
                list: [],   //数据列表
				addEditTitle: '新增公告',
				addEditFlag: false,
				addEditData: null,
                supplierList:[],    //供应商列表
			}
		},
		mounted() {
            this.getList();
		},
		methods: {
			search() {
				this.filter.pageNo = 1;
				this.getList();
			},
			pageSizeChange(size) {
				this.filter.pageSize = size;
				this.getList();
			},
			currentChange(page) {
				this.filter.pageNo = page;
				this.getList();
			},
			// 获取列表
			async getList() {
				this.tableLoading = true;
				let params = this.filter;
				let { success, data } = await findNoticeList(params);
				if (success) {
					this.list = data.records || [];
					this.total = data.total;
				}
				setTimeout(() => {
					this.tableLoading = false;
				}, 300);
			},
			addItem() {
				this.addEditFlag = true;
				this.addEditTitle = '新增品牌';
				this.addEditData = {
					"content": "",
                    "status": 0,
                    "taskPublishTime": "",
                    "title": ""
				};
			},
			//编辑/新增品牌
			editItem({row}) {
				this.addEditTitle = '编辑品牌';
                let temp = JSON.parse(JSON.stringify(row));
				this.addEditData = {...temp};
                console.log(this.addEditData);
                this.addEditFlag = true;
			},
			//删除公告
			async deleteItem({row}) {
                this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {}).then(async () => {
                    let result = await delNotice({id:row.id});
                    if (result.success) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList();
                        
                    } else {
                        this.$message({
                            type: 'error',
                            message: result.alertMsg
                        });
                    }
                })
			},
            //发布公告
			async updateNoticeStatus({row}) {
                this.$confirm('确认发布当前公告?', '提示', {}).then(async () => {
                    let result = await updateNoticeStatus({id:row.id});
                    if (result.success) {
                        this.$message({
                            type: 'success',
                            message: '发布成功!'
                        });
                        this.getList();
                        
                    } else {
                        this.$message({
                            type: 'error',
                            message: result.alertMsg
                        });
                    }
                })
			},
			//关闭弹出层
			closedLayer(flag) {
				this.addEditFlag = false;
				if(flag) {
					this.getList();
				}
			},
			clearDataFunc() {
				this.addEditData = null;
			},
		}
	}
</script>

<style scoped lang="less">
	.iv-heaer>img {
		width: 50px;
		height: 50px;
	}
</style>