<template>
    <div>
        <el-form :model="formData" ref="addEditForm" label-width="120px">
            <el-form-item label="公告标题：" prop="title" :rules="[{required:true,message:'公告标题不能为空',trigger: 'blur'}]">
                <el-input v-model="formData.title" placeholder="请输入公告标题，最多输入20个字" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="公告内容：" prop="content" :rules="[{required:true,message:'公告标题不能为空',trigger: 'blur'}]">
                <quill-editor ref="myTextEditor" v-model="formData.content" :options="quillOption" style="width:100%;height:300px;"></quill-editor>            
            </el-form-item>
            <el-form-item label="定时发布：" prop="taskPublishTime" style="margin-top:100px;">
                <el-date-picker
                    v-model="formData.taskPublishTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm:ss" 
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态：" prop="status">
                <el-radio v-model="formData.status" :label="1">已发布</el-radio>
                <el-radio v-model="formData.status" :label="0">待发布</el-radio>
            </el-form-item>
            
            <div style="text-align:right;">
                <el-button @click="cancelAddEdit">取消</el-button>
                <el-button type="primary" @click="saveAddEdit">确认</el-button>
            </div>
        </el-form>
       
    </div>
</template>

<script>
    import { 
        addNotice, 
        updateNotice 
    } from "@/api/setting";
    import { quillEditor } from 'vue-quill-editor'
    import quillConfig from '@/components/quill-config.js'
	export default {
        components: {
            quillEditor
        },
		props: {
			formData: {
				type: Object,
				default: null,
			},
            supplierList:{
                type: Array
            }
		},
		data() {
			return {
                quillOption: quillConfig, //富文本
			}
		},
        mounted() {
			this.$refs.myTextEditor.quill.enable(false);
            this.$nextTick(function() {
                if (this.$refs.myTextEditor.quill) {
                    this.$refs.myTextEditor.quill.enable(true);
                    this.$refs.myTextEditor.quill.blur();
                    window.scrollTo(0, 0);
                }
            });
		},
		methods: {
			//取消
			cancelAddEdit() {
				this.$refs["addEditForm"].clearValidate();
				this.$emit('closeLayer', false);
			},
			//确认提交
			async saveAddEdit() {
				let params = { ...this.formData};
                params = JSON.parse(JSON.stringify(params));
				let res = {};
				if(params.id) {
					res = await updateNotice(params);
				} else {
					res = await addNotice(params);
				}
				if(res.success) {
					this.$emit('closeLayer', true);
				}else{
                    this.$message({
                        type: 'error',
                        message: res.alertMsg
                    });
                }
			},
		}
	}
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}
}
</style>